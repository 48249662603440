<template>
  <div class="qr">
    <QRcode
      v-if="link"
      :key="link"
      :data="link"
      is-switch
    />

    <ActionForm
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      @validate="onPassedValidation"
    >
      <template #default="{ errors, action }">
        <AppText variant="div" pt="40px" mb="10px">
          {{ t('component.changeToken.enterCode') }}
        </AppText>
        <SplitInput
          v-model="requestFields.verifyCode"
          type="dashed"
          :state="errors?.verifyCode ? 'error' : ''"
          is-auto-clear-error
          :error-message="errors.verifyCode?.message"
        />
        <div class="btn-wrapper d-flex justify-content-between">
          <FButton
            style="flex: 1 1 0"
            @click="action"
          >
            {{ t('common.continue') }}
          </FButton>
          <FButton
            style="flex: 1 1 0"
            type="plain"
            @click="closeModal"
          >
            {{ t('common.cancel') }}
          </FButton>
        </div>
      </template>
    </ActionForm>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import QRcode from '@/components/QRcode.vue';
import SplitInput from '@/components/Inputs/SplitInput.vue';

import { ActionForm } from '@/validation';
import { useModel } from '@/composables/useModel';
import { useStore } from '@/store';
import { codeValidation } from '@/validation/schemas';

export default defineComponent({
  components: {
    SplitInput,
    QRcode,
    ActionForm,
  },
  props: {
    link: {
      type: String,
      default: '',
    },
    fn: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['next', 'close'],
  setup(props, { emit }) {
    const { value: propCodes } = useModel(props, emit, { prop: 'codes' });

    const {
      validationSchema,
      requestFields,
      fieldsErrors,
      processError,
    } = codeValidation();

    const onPassedValidation = async () => {
      const store = useStore();
      const { data, isSuccess, errorObject } = await props.fn(requestFields.verifyCode, `Switch: ${store.user.companyName} (${store.currencyValue})`);
      if (isSuccess) {
        emit('next');
        propCodes.value = data.recoveryKeys;
      } else {
        processError(errorObject, 'wrongToken');
      }
    };

    const closeModal = () => {
      emit('close');
    };

    return {
      validationSchema,
      requestFields,
      fieldsErrors,
      onPassedValidation,

      closeModal,
    };
  },
});
</script>

<style scoped lang="scss">
.qr {
  width: 100%;
}
:deep(.f-input) {
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>
