import { ref } from 'vue';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export const getOffsetByTimezone = (tz) => {
  if (!tz || tz === 'UTC') {
    return '+00:00';
  }

  dayjs.extend(timezone);

  const offset = {
    value: 0,
    sign: '+',
  };

  try {
    const offsetMinutes = dayjs().tz(tz)?.$offset;
    const offsetHours = (offsetMinutes || 0) / 60;
    offset.value = Math.abs(offsetHours);
    offset.sign = offsetHours < 0 ? '-' : '+';
  } catch (e) {
    console.error(e);
  }

  const result = offset.value < 10 ? `${offset.sign}0${offset.value}:00` : `${offset.sign}${offset.value}:00`;
  return result;
};

export const useTimezones = () => {
  const timezones = ref([]);

  async function loadTimezones() {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    if (!timezones.value.length) {
      const { getTimeZones } = await import('@vvo/tzdb');
      const rawTimezones = getTimeZones({ includeUtc: true });

      timezones.value = rawTimezones.map((tz) => {
        const time = getOffsetByTimezone(tz.name);
        const text = `${tz.name} (${time})`;

        return { text, time };
      });
    }
  }

  return {
    timezones,
    loadTimezones,
  };
};
