import { useFetch } from '@/api';
import { convertBase64ToFile } from '@/utils/images';

export const updateAdminSettings = async (settings) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess } = await axiosWrapper({
    type: 'patch',
    url: '/settings/admin/profile',
    params: settings,
  });

  return { isSuccess };
};

export const updateAdminPicture = async ({ originalImage, croppedImage }) => {
  const originalImageFile = await convertBase64ToFile(originalImage, 'profilePhoto.png', 'image/jpeg');
  const croppedImageFile = await convertBase64ToFile(croppedImage, 'croppedPhoto.png');

  const { axios } = useFetch();
  const result = await axios.postForm('/admin/profile-picture', {
    original: originalImageFile,
    crop: croppedImageFile,
  });

  const isSuccess = result.data.success;
  return { isSuccess };
};

export const deleteAdminPicture = async () => {
  const { axiosWrapper } = useFetch();
  const { isSuccess } = await axiosWrapper({
    type: 'delete',
    url: '/admin/profile-picture',
  });

  return { isSuccess };
};

export const generateQrCode = async (token) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess, errorObject } = await axiosWrapper({
    type: 'post',
    url: '/admin/totp/change',
    params: { totpCode: String(token) },
  });

  const otpLink = isSuccess ? `otpauth://totp/${data.label}?secret=${data.secret}` : '';

  return { otpLink, isSuccess, errorObject };
};

export const enableNewToken = async (token) => {
  const { axiosWrapper, successHandler } = useFetch();
  const { data, isSuccess, errorObject } = await axiosWrapper({
    type: 'post',
    url: '/admin/totp/recovery/enable',
    params: { totpCode: String(token) },
  });

  if (isSuccess) {
    successHandler('TOTP code has been updated');
  }

  return { data, isSuccess, errorObject };
};
