<template>
  <div>
    <ActionForm
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      @validate="onPassedValidation"
    >
      <template #default="{ errors, action }">
        <SplitInput
          v-model="requestFields.verifyCode"
          type="dashed"
          :state="errors?.verifyCode ? 'error' : ''"
          is-auto-clear-error
          :error-message="errors.verifyCode?.message"
        />
        <div class="d-flex justify-content-between">
          <FButton
            style="flex: 1 1 0"
            @click="action"
          >
            {{ t('component.changeToken.resetToken') }}
          </FButton>
          <FButton
            type="plain"
            style="flex: 1 1 0"
            @click="closeModal"
          >
            {{ t('common.cancel') }}
          </FButton>
        </div>
      </template>
    </ActionForm>
  </div>
</template>

<script setup>
import SplitInput from '@/components/Inputs/SplitInput.vue';

import { ActionForm } from '@/validation';
import { useStore } from '@/store';
import { codeValidation } from '@/validation/schemas';

const props = defineProps({
  fn: {
    type: Function,
    default: () => {},
  },
});

const emit = defineEmits(['next', 'close']);

const link = defineModel({ type: String, default: '' });

const {
  validationSchema,
  requestFields,
  fieldsErrors,
  processError,
} = codeValidation();

const onPassedValidation = async () => {
  const store = useStore();
  const { otpLink, isSuccess, errorObject } = await props.fn(requestFields.verifyCode?.toUpperCase(), `Switch: ${store.user.companyName} (${store.currencyValue})`);
  link.value = otpLink;
  if (isSuccess) {
    emit('next');
  } else {
    processError(errorObject, 'wrongToken');
  }
};

const closeModal = () => {
  emit('close');
};
</script>
