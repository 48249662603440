<template>
  <TransitionWrapper>
    <div class="w-100 wrapper">
      <div class="header">
        <div class="d-flex align-items-center">
          <AppAvatar
            :key="pictures?.croppedImage"
            :src="pictures?.croppedImage"
            is-hover-disabled
            is-badge-disabled
            size="60px"
            style="margin-right: 20px;"
          />
          <div>
            <TooltipCopy is-icon-hidden>
              <AppText
                variant="div"
                size="22px"
                class="font-medium"
                :line-height="1.2"
                mb="8px"
              >
                {{ username }}
              </AppText>
            </TooltipCopy>
            <div class="d-flex" style="gap: 8px;">
              <AppIcon name="switch-logo" size="18px" fill="var(--color-black-04)" />
              <AppText
                variant="div"
                size="14px"
                :line-height="1.2"
                color="var(--color-black-04)"
                class="is-first-letter-capitalized"
              >
                {{ role }}
              </AppText>
            </div>
          </div>
        </div>
      </div>

      <div class="profile">
        <div class="options">
          <AppText size="15px" class="font-medium">
            Profile Picture
          </AppText>
          <AvatarManagement
            :pictures="profilePictures"
            :is-exist="isAvatarExists"
            :is-avatar-visible="false"
            @save="onPictureSave"
            @delete="onPictureDelete"
          />

          <div />
          <div />

          <AppText size="15px" class="font-medium" style="align-self: start;">
            TOTP
          </AppText>

          <div class="d-flex flex-column" style="gap: 8px; align-self: start;">
            <AppText>
              <a class="font-medium" @click="isTotpModalOpen = true">Change token</a>
            </AppText>

            <AppText color="var(--color-939393)">
              Last changed:
              <DateFormatter :format="dateTimeFormat" :date="totpChangedAt" />
            </AppText>
          </div>

          <AppText size="15px" class="font-medium">
            Email
          </AppText>

          <AppText v-if="role === 'Admin'" class="font-medium">
            {{ email }}
          </AppText>
          <FInput v-else v-model="email">
            <template #prefix>
              <AppIcon
                name="mail"
                opacity="0.4"
                size="18px"
                is-img-tag
              />
            </template>
          </FInput>

          <AppText size="15px" class="font-medium">
            Time Zone
          </AppText>

          <TimezoneSelect
            v-model="currentTimezoneCode"
          />

          <AppText size="15px" class="font-medium">
            Time Format
          </AppText>

          <FSelect
            v-model="currentTimeFormat"
            :options="timeFormats"
            has-arrow
            option-text="label"
            option-value="value"
            no-margin
            return-key="value"
            popper-class="settings-select-popper"
          />

          <AppText size="15px" class="font-medium">
            Date Format
          </AppText>

          <FSelect
            v-model="currentDateFormat"
            :options="dateFormats"
            has-arrow
            option-text="label"
            option-value="value"
            no-margin
            return-key="value"
            popper-class="settings-select-popper"
          />

          <AppText size="15px" class="font-medium">
            Number Format
          </AppText>

          <FSelect
            v-model="currentNumberFormat"
            :options="numberFormats"
            has-arrow
            option-text="label"
            option-value="value"
            no-margin
            return-key="value"
            popper-class="settings-select-popper"
          />

          <div>&nbsp;</div>

          <div>
            <FButton
              no-margin
              :loading="isSaveProcessing"
              @click="onUpdateSettings"
            >
              Save Changes
            </FButton>
          </div>
        </div>
      </div>
    </div>
    <AdminChangeTotpModal
      v-model="isTotpModalOpen"
      :totp-function="generateQrCode"
      :qr-function="enableNewToken"
    />
  </TransitionWrapper>
</template>

<script>
import { computed, ref } from 'vue';

import AppAvatar from '@/components/Avatars/AppAvatar.vue';
import AvatarManagement from '@/components/Modules/AvatarManagement.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import TimezoneSelect from '@/components/Modules/TimezoneSelect.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';
import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import { readAdminProfile } from '@/api/admin';
import { dateFormats, timeFormats, numberFormats } from '@/common/data';
import { successHandler } from '@/composables/useFetch';
import { useAdmin } from '@/composables/useAdmin';
import { useBus } from '@/composables/useBus';
import { useProfile } from '@/composables/useProfile';
import { useToken } from '@/composables/useToken';

import AdminChangeTotpModal from './AdminChangeTotpModal.vue';
import {
  deleteAdminPicture,
  enableNewToken,
  generateQrCode,
  updateAdminPicture,
  updateAdminSettings,
} from './api';

export default {
  name: 'AdminProfile',
  components: {
    AppAvatar,
    TransitionWrapper,
    DateFormatter,

    AvatarManagement,
    TimezoneSelect,
    AdminChangeTotpModal,
    TooltipCopy,
  },
  setup() {
    const { admin, dateFormat, timeFormat } = useAdmin();
    const username = computed(() => admin.value.username);

    const { tokenRole } = useToken();
    const role = computed(() => tokenRole.value);

    const currentTimezoneCode = ref(admin.value.timezone?.code);

    const currentDateFormat = ref(dateFormat.value);
    const currentTimeFormat = ref(timeFormat.value);
    const currentNumberFormat = ref(admin.value.numberFormat);

    const shouldPicturesBeenUpdated = ref(false);
    const shouldPicturesBeenDeleted = ref(false);
    const pictures = ref({
      croppedImage: admin.value.profilePicture?.cropUrl,
      originalImage: admin.value.profilePicture?.originalUrl,
    });

    const isAvatarExists = computed(() => Boolean(admin.value.profilePicture?.cropUrl && admin.value.profilePicture?.originalUrl));

    const shouldSettingsBeingUpdated = computed(() => currentTimezoneCode.value !== admin.value.timezone?.code
      || currentDateFormat.value !== admin.value.dateFormat
      || currentTimeFormat.value !== admin.value.timeFormat
      || currentNumberFormat.value !== admin.value.numberFormat);

    const shouldProfileBeingFetched = computed(() => shouldSettingsBeingUpdated.value
      || (shouldPicturesBeenUpdated.value)
      || (shouldPicturesBeenDeleted.value));

    const isSaveProcessing = ref(false);
    const onUpdateSettings = async () => {
      if (shouldSettingsBeingUpdated.value) {
        isSaveProcessing.value = true;
        await updateAdminSettings({
          timezoneCode: currentTimezoneCode.value,
          dateFormat: currentDateFormat.value,
          timeFormat: currentTimeFormat.value,
          numberFormat: currentNumberFormat.value,
        });
      }

      if (shouldPicturesBeenUpdated.value) {
        isSaveProcessing.value = true;
        const { isSuccess } = await updateAdminPicture(pictures.value);
        if (isSuccess && !shouldSettingsBeingUpdated.value) {
          successHandler('Settings has been updated');
        }
      }
      if (shouldPicturesBeenDeleted.value) {
        isSaveProcessing.value = true;
        const { isSuccess } = await deleteAdminPicture();
        if (isSuccess && !shouldSettingsBeingUpdated.value) {
          successHandler('Settings has been updated');
        }
      }

      if (shouldProfileBeingFetched.value) {
        await readAdminProfile({ isFreshResponse: true });
        shouldPicturesBeenUpdated.value = false;
        shouldPicturesBeenDeleted.value = false;
      }
      isSaveProcessing.value = false;
    };

    const profilePictures = computed(() => ({
      profileAvatar: admin.value.profilePicture?.cropUrl,
      profileOriginalAvatar: admin.value.profilePicture?.originalUrl,
    }));

    const onPictureSave = ({ originalImage, croppedImage }) => {
      pictures.value = { originalImage, croppedImage };
      shouldPicturesBeenUpdated.value = true;
      shouldPicturesBeenDeleted.value = false;
    };
    const onPictureDelete = () => {
      shouldPicturesBeenDeleted.value = true;
      shouldPicturesBeenUpdated.value = false;
      pictures.value = { originalImage: null, croppedImage: null };
    };

    const isTotpModalOpen = ref(false);

    const { email } = useAdmin();
    const { dateTimeFormat } = useProfile();
    const totpChangedAt = computed(() => admin.value.twoFaUpdatedAt);

    const { emitHandler } = useBus();
    emitHandler('update-profile-page', async () => {
      await readAdminProfile({ isFreshResponse: true });
    });

    return {
      username,
      role,

      currentTimezoneCode,

      currentDateFormat,
      currentTimeFormat,
      dateFormats,
      timeFormats,

      currentNumberFormat,
      numberFormats,

      profilePictures,
      pictures,
      isAvatarExists,

      isSaveProcessing,

      isTotpModalOpen,
      dateTimeFormat,
      totpChangedAt,
      email,

      onUpdateSettings,
      onPictureSave,
      onPictureDelete,

      enableNewToken,
      generateQrCode,
    };
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  margin: 40px;
  max-width: 520px;
}

.header {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--color-E6E6E6);
  margin-bottom: 32px;
}

.options {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 36px 0 60px 44px 44px 44px 44px 44px 44px;
  gap: 20px 60px;
  grid-template-areas:
  ". ."
  ". ."
  ". ."
  ". ."
  ". ."
  ". .";
  align-items: center;
}
</style>
