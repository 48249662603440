<template>
  <div class="timezone-select">
    <FSelect
      v-model="timezone"
      :options="processedTimezones"
      has-arrow
      option-value="text"
      :option-label="`${timezone}`"
      :placeholder="`${timezone || 'UTC'}`"
      filterable
      no-margin
      popper-class="settings-select-popper"
      @visible-change="onDropdownChange"
    >
      <template #option="{ item }">
        {{ item.text }}
      </template>
    </FSelect>
  </div>
</template>

<script setup>
import { onBeforeUnmount, ref } from 'vue';

import { useTimezones } from '@/composables/useTimezones';

const timezone = defineModel({ type: String, default: null });

const { timezones, loadTimezones } = useTimezones();

const init = async () => {
  await loadTimezones();
  prepareTimezones();
};

init();

const processedTimezones = ref([]);
const timerId = ref(null);

// Method to prevent page stuck when loading timezones
const prepareTimezones = () => {
  let i = 0;

  if (timezone.value === null) {
    timezone.value = 'Etc/UTC';
  }

  timerId.value = setInterval(() => {
    if (i >= timezones.value.length) {
      clearInterval(timerId.value);
      return;
    }

    const end = Math.min(i + 10, timezones.value.length);
    processedTimezones.value.push(...timezones.value.slice(i, end));
    i += 10;
  }, 10);
};

onBeforeUnmount(() => {
  if (timerId.value) { clearInterval(timerId.value); }
});

const isDropdownVisible = ref(true);

const onDropdownChange = (isVisible) => {
  isDropdownVisible.value = !isVisible;
};

</script>

<style scoped lang="scss">
.timezone-select {
  position: relative;
}
</style>
