<template>
  <SmoothModal
    v-model="isModalOpen"
    :has-header="false"
    width="440px"
  >
    <StepsWrapper
      icon="shield-2fa"
      :steps="['code', 'qr', 'finish']"
      :current-step="currentStep"
      :is-description-visible="currentStep !== 'finish'"
    >
      <template #title>
        {{ title }}
      </template>
      <template #description>
        {{ description }}
      </template>

      <EnableTOTP
        v-if="currentStep === 'code'"
        v-model:link="link"
        :fn="totpFunction"
        @next="onNext('qr')"
        @close="toggleModal"
      />
      <QrCode
        v-if="currentStep === 'qr'"
        :link="link"
        :fn="qrFunction"
        @next="onNext('finish')"
        @close="toggleModal"
      />

      <template v-if="currentStep === 'finish'">
        <AppText variant="div" mb="20px">
          You have successfully replaced your old token with the new one. TOTPs from the old token will no longer work.
        </AppText>
        <AppText variant="div">
          No recovery options exist in case of lost token.
        </AppText>

        <FButton style="margin-top: 40px;" size="small" is-full-width @click="onFinish">
          Finish
        </FButton>
      </template>
    </StepsWrapper>
  </SmoothModal>
</template>

<script setup>
import {
  computed, ref, watch,
} from 'vue';

import SmoothModal from '@/components/SmoothModal';
import StepsWrapper from '@/components/Containers/StepsWrapper.vue';
import EnableTOTP from '@/components/Modules/ModalToChangeTotp/EnableTOTP.vue';
import QrCode from '@/components/Modules/ModalToChangeTotp/QrCode.vue';

defineProps({
  totpFunction: {
    type: Function,
    default: () => {},
  },
  qrFunction: {
    type: Function,
    default: () => {},
  },
});

const emit = defineEmits(['update']);

const isModalOpen = defineModel({ type: Boolean, default: false });
const currentStep = ref('code'); // code, qr, finish

const onNext = (step) => {
  currentStep.value = step;
  emit('update');
};

const toggleModal = () => {
  isModalOpen.value = !isModalOpen.value;
};

watch(isModalOpen, (isOpen) => {
  if (!isOpen) {
    currentStep.value = 'code';
  }
});

const link = ref('');

const title = computed(() => {
  if (currentStep.value === 'code') {
    return 'Change Token';
  }

  if (currentStep.value === 'qr') {
    return 'Setup New Token';
  }

  return 'Token Changed';
});

const description = computed(() => {
  if (currentStep.value === 'code') {
    return 'Enter the TOTP visible on your authenticator app.';
  }

  if (currentStep.value === 'qr') {
    return 'Use any software authenticator to scan the QR below and generate a token.';
  }

  return '';
});

const onFinish = () => {
  isModalOpen.value = false;
  currentStep.value = 'code';
};
</script>
